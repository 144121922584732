export const FarmItems = [
  {
    title: "HOKUTO-WFTM Spooky LP",
    url: "/farms/1",
    poolid: 1,
    cName: "farm-links",
    symbol1: "HOKUTO",
    symbol2: "WFTM",
    boost: "5.5",
  },
  {
    title: "WFTM-DAI Hokuto Variable LP",
    url: "/farms/2",
    poolid: 2,
    cName: "farm-links",
    symbol1: "WFTM",
    symbol2: "DAI",
    boost: "3",
  },
  {
    title: "BTC-ETH Hokuto Variable LP",
    url: "/farms/3",
    poolid: 3,
    cName: "farm-links",
    symbol1: "BTC",
    symbol2: "ETH",
    boost: "7.49",
  },
  {
    title: "YFI-ETH Hokuto Variable LP",
    url: "/farms/4",
    poolid: 4,
    cName: "farm-links",
    symbol1: "YFI",
    symbol2: "ETH",
    boost: "0.51",
  },
  {
    title: "BTC-renBTC Hokuto Stable LP",
    url: "/farms/5",
    poolid: 5,
    cName: "farm-links",
    symbol1: "BTC",
    symbol2: "renBTC",
    boost: "0.1",
  },
  {
    title: "USDC-DAI Hokuto Stable LP",
    url: "/farms/6",
    poolid: 6,
    cName: "farm-links",
    symbol1: "USDC",
    symbol2: "DAI",
    boost: "0.1",
  },
  {
    title: "WFTM-ETH Hokuto Variable LP",
    url: "/farms/7",
    poolid: 7,
    cName: "farm-links",
    symbol1: "WFTM",
    symbol2: "ETH",
    boost: "0.5",
  },
  {
    title: "WFTM-BTC Hokuto Variable LP",
    url: "/farms/8",
    poolid: 8,
    cName: "farm-links",
    symbol1: "WFTM",
    symbol2: "BTC",
    boost: "0.51",
  },
  {
    title: "WFTM-USDC Hokuto Variable LP",
    url: "/farms/9",
    poolid: 9,
    cName: "farm-links",
    symbol1: "WFTM",
    symbol2: "USDC",
    boost: "2",
  },
  {
    title: "fBEETS-USDC Hokuto Variable LP",
    url: "/farms/11",
    poolid: 11,
    cName: "farm-links",
    symbol1: "fBEETS",
    symbol2: "USDC",
    boost: "0.71",
  },
  {
    title: "BOO-FTM Hokuto Variable LP",
    url: "/farms/12",
    poolid: 12,
    cName: "farm-links",
    symbol1: "BOO",
    symbol2: "WFTM",
    boost: "1.26",
  },
  {
    title: "HOKUTO-beHOKUTO Hokuto Stable LP",
    url: "/farms/13",
    poolid: 13,
    cName: "farm-links",
    symbol1: "HOKUTO",
    symbol2: "beHOKUTO",
    boost: "0.76",
  },
  {
    title: "BELUGA-FTM Hokuto Variable LP",
    url: "/farms/14",
    poolid: 14,
    cName: "farm-links",
    symbol1: "BELUGA",
    symbol2: "WFTM",
    boost: "1.99",
  },
  {
    title: "HOKUTO-FTM Hokuto Variable LP",
    url: "/farms/15",
    poolid: 15,
    cName: "farm-links",
    symbol1: "HOKUTO",
    symbol2: "WFTM",
    boost: "1",
  },
  {
    title: "oxSOLID-SOLID Hokuto Stable LP",
    url: "/farms/16",
    poolid: 16,
    cName: "farm-links",
    symbol1: "SOLID",
    symbol2: "oxSOLID",
    boost: "2.14",
  },
  {
    title: "Never HOKUTO yearn boosted Beets LP",
    url: "/farms/17",
    poolid: 17,
    cName: "farm-links",
    symbol1: "HOKUTO",
    symbol2: "WFTM",
    boost: "5.5",
  },
  {
    title: "fBEETS-beBEETS Hokuto Variable LP",
    url: "/farms/18",
    poolid: 18,
    cName: "farm-links",
    symbol1: "fBEETS",
    symbol2: "beBEETS",
    boost: "4.47",
  },
  {
    title: "BNB-FTM Hokuto Variable LP",
    url: "/farms/19",
    poolid: 19,
    cName: "farm-links",
    symbol1: "BNB",
    symbol2: "WFTM",
    boost: "0.88",
  },
  {
    title: "beHND-HND Hokuto Stable LP",
    url: "/farms/20",
    poolid: 20,
    cName: "farm-links",
    symbol1: "beHND",
    symbol2: "HND",
    boost: "0.96",
  },
  {
    title: "HND-FTM Hokuto Variable LP",
    url: "/farms/21",
    poolid: 21,
    cName: "farm-links",
    symbol1: "HND",
    symbol2: "WFTM",
    boost: "0.5",
  },
  {
    title: "ELITE-ETH Hokuto Variable LP",
    url: "/farms/22",
    poolid: 22,
    cName: "farm-links",
    symbol1: "ELITE",
    symbol2: "ETH",
    boost: "0.1",
  },
];
