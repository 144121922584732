export const networks = [250]

export const ChainId = {
  // MAINNET: 1,
  FANTOM: 250
};

export const routerAddress = new Map();
routerAddress.set(ChainId.FANTOM, "0xB58A04bb08b72c4329919cEa6150efF96aC83b63");

