export const MenuItems = [
  {
    title: "Swap",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "Liquidity",
    url: "/liquidity",
    cName: "nav-links",
  },
  ,
  {
    title: "Farms",
    url: "/farms",
    cName: "nav-links",
  },
  {
    title: "Stake",
    url: "/stake",
    cName: "nav-links",
  },
];
